import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from './selector'; // Ensure this path is correct

const PermissionsContext = createContext();

export const usePermissions = () => useContext(PermissionsContext);

export const PermissionsProvider = ({ children }) => {
    const user = useSelector(selectUser); // Ensure you select the user correctly

    // Directly use user.userPermissions
    const rolePermissions = user ? user.userPermissions : [];


    return (
        <PermissionsContext.Provider value={{ rolePermissions }}>
            {children}
        </PermissionsContext.Provider>
    );
};
