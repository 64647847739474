import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../redux/Authentication";
import { get, post, del } from '../../services/ApiService';
import "../custom-style.css";
import toast, { Toaster } from 'react-hot-toast';
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const Body = {
                email,
                password
            };
            const res = await post(`api/auth/login`, Body);
            if (res.success) {
                toast.success('Login successfully');
                const expirationTime = Math.floor(Date.now() / 1000) + 7200;
                localStorage.setItem("token", res.authToken);
                localStorage.setItem("token-expiration", expirationTime);
                dispatch(loginSuccess(res.user));
                setIsLoading(false);
                setEmail('');
                setPassword('');
                navigate('/');

            } else {
                toast.error(res.error);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error)
            toast.error('An error occurred. Please try again.');
        }
    };

  return (
    <>
    <Toaster 
        position="top-center"
        reverseOrder={false}
    />
    <main id="content" className="wrapper layout-page">
        <section className="pb-lg-20 pb-16">
            <div className="container">
            <div className="text-center pt-13 mb-12 mb-lg-15">
                <div className="text-center">
                <h2 className="fs-36px mb-11 mb-lg-14">Login</h2>
                </div>
            </div>
            <div className="row no-gutters">
                <div className="col-lg-10 mx-auto">
                <div className="row no-gutters">
                    <div className="col-lg-6 mb-15 mb-lg-0 pe-xl-2">
                    <form className onSubmit={handleSubmit}>
                        <div className="form-group mb-6">
                            <label htmlFor="user_login_email" className="visually-hidden">Email address</label>
                            <input type="email" className="form-control" id="user_login_email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" required/>
                        </div>
                        <div className="form-group mb-6">
                            <label htmlFor="user_login_password" className="visually-hidden">Password</label>
                            <input type="password" className="form-control" id="user_login_password" placeholder="Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}  required/>
                        </div>
                        <Link className="d-inline-block fs-15 lh-12 mb-7">Forgot your password?</Link>
                        <button type="submit" className="btn btn-primary w-100 mb-7" disabled={isLoading}>
                            {isLoading && <i className="fa fa-spinner fa-spin"></i>}  Submit
                        </button>
                    </form>
                    </div>
                    <div className="col-lg-6 ps-lg-6 text-center ps-xl-12 align-items-center justify-content-center d-flex">
                        <img src='/assets/images/logo.png' style={{width:'300px'}} />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
    </main>

    </>
  )
}

export default Login